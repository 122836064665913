import React, {useEffect, useState} from 'react';

import {RideReport} from "@models/travelAllowance/rideReport";
import Pane from '@components/pane/pane.component';
import {getRideDocumentContentByType} from "@services/travelAllowance/rideDocument.service";
import {RideDocumentType} from "@enums/travelAllowance/rideDocument";
import MyDataSkeleton from "@app/myData/components/myDataSkeleton.component";

type RideDocumentPaneProps = {
    topEdge?: number,
    report: RideReport,
    type: RideDocumentType,
}

const RideDocumentPane: React.FC<RideDocumentPaneProps> = ({topEdge, report, type}: RideDocumentPaneProps) => {
    const [content, setContent] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);

        fetchRideDocumentContent()
            .then(() => {
                setLoading(false);
            });
    }, [type]);

    const fetchRideDocumentContent = async () => {
        const rideDocumentContent = await getRideDocumentContentByType(report.id, type);

        setContent(rideDocumentContent);
    }

    return (
        <Pane topEdge={topEdge} marginTop={40} paddingBottom={147}>
            {
                loading &&
                <Pane topEdge={topEdge}>
                    <MyDataSkeleton/>
                </Pane>
            }
            <div style={{overflowY: 'auto'}}>
                {
                    !loading && content && <div dangerouslySetInnerHTML={{__html: content}}/>
                }
            </div>
        </Pane>
    );
};

export default RideDocumentPane;
