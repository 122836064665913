import React, {useState} from 'react';
import {IonAlert, IonRow} from '@ionic/react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {Car} from "@models/travelAllowance/car";

import {IonCardShadowStyle, StyledButton} from "@app/travelAllowance/travelAllowance.style";

import {ReactComponent as EditIcon} from "@assets/images/travelAllowance/edit.svg";
import {ReactComponent as BinIcon} from "@assets/images/travelAllowance/bin.svg";

import {Links} from "@app/links";

type CarListRowProps = {
    car: Car;
    handleRemove: (carId: number) => void;
}

const CarListRow: React.FC<CarListRowProps> = ({car, handleRemove}: CarListRowProps) => {
    const history = useHistory();
    const {t} = useTranslation();

    const [showRemoveConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);

    return (
        <IonCardShadowStyle>
            <IonAlert
                isOpen={showRemoveConfirmationAlert}
                onDidDismiss={() => setShowConfirmationAlert(false)}
                header={t('travelAllowance.removeConfirmationAlert.title')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowConfirmationAlert(false);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            handleRemove(car.id);
                        }
                    }
                ]}
            />
            <div className="wrapper">
                <div>
                    <IonRow className="title-card">
                        {car.brand} {car.model}
                    </IonRow>
                    <IonRow className="title-small">
                        {car.licensePlate} {car.capacity}&nbsp;ccm
                    </IonRow>
                </div>
                <div className="wrapper-btn"
                     style={{flexDirection: "row", alignItems: "center"}}>
                    <StyledButton onClick={() => history.push(Links.main + Links.travelAllowance.car.edit + '/' + car.id)}>
                        <div className="btn center">
                            <EditIcon/>
                        </div>
                    </StyledButton>
                    <StyledButton onClick={() => setShowConfirmationAlert(true)}>
                        <div className="btn center">
                            <BinIcon/>
                        </div>
                    </StyledButton>
                </div>
            </div>
        </IonCardShadowStyle>
    );
};

export default CarListRow;