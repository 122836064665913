import React, {useEffect, useState} from 'react';
import {IonSkeletonText} from "@ionic/react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import {GOOGLE_MAPS_API_KEY} from "../../../variables/variables";

import {
    StyledAutocompleteList,
    StyledAutocompleteListItem
} from "@components/google/cityAutocomplete/cityAutocomplete.style";
import {StyledInput} from "@components/form/input/input.style";

interface CityAutocompleteProps extends React.HTMLAttributes<HTMLInputElement> {
    setValue?: (value: string) => void,
    setPlaceId?: (placeId: string) => void,
    setCity?: (city: string) => void,
    setCountry?: (country: string) => void,
    onChangeCallback?: (...event: any[]) => void;
    debounce?: number,
    disabled?: boolean,
}

type Location = {
    short_name: string,
    long_name: string,
    types: string[]
}

type City = Location;

type Country = Location;

type LoadedPlace = {
    city: City,
    country: Country,
    place_id: string,
    place_name: string
}

/**
 * @see src/components/google/cityAutocomplete/README.md
 */
const CityAutocomplete: React.FC<CityAutocompleteProps> = ({setValue, setPlaceId, setCity, setCountry, debounce, onChangeCallback, disabled, ...props}: CityAutocompleteProps) => {
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading
    } = useGoogle({
        apiKey: GOOGLE_MAPS_API_KEY,
        debounce: debounce ?? 500
    });

    const [searchValue, setSearchValue] = useState<string>('');
    const [loadedPlaces, setLoadedPlaces] = useState<LoadedPlace[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoadedPlaces([]);
    }, []);

    useEffect(() => {
        setSearchValue(props.defaultValue as string);
    }, [props.defaultValue]);

    useEffect(() => {
        if (placePredictions.length && placesService) {
            setLoadedPlaces([]);
            setLoading(true);

            const localityPlacePredictions = placePredictions.filter((item: any) => item.types.includes('locality'))

            localityPlacePredictions.forEach((placePrediction: any) => {
                placesService.getDetails(
                    {
                        placeId: placePrediction.place_id,
                    },
                    (placeDetails: any) => handleSetLoadedPlace(placeDetails)
                );
            });

            setLoading(false);
        }
    }, [placePredictions]);

    const handleSetLoadedPlace = (placeDetails: any) => {
        const placeData = normalizePlaceData(placeDetails);
        const normalizedLoadedPlace: LoadedPlace = {
            ...placeData,
            place_name: `${placeData.city.long_name}, ${placeData.country.short_name}`,
            place_id: placeDetails.place_id
        };

        setLoadedPlaces((prevState: LoadedPlace[]) => [...prevState, normalizedLoadedPlace]);
    }

    const normalizePlaceData = (placeDetails: any): { city: City, country: Country } => {
        const city = placeDetails.address_components
            .find((item: any) => item.types.includes('locality'))
        const country = placeDetails.address_components
            .find((item: any) => item.types.includes('country'))

        return {city, country};
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        getPlacePredictions({input: e.target.value});
    }

    const handlePlaceSelect = (place: LoadedPlace) => {
        setSearchValue(place.place_name);
        setLoadedPlaces([]);

        if (typeof setValue === 'function') {
            setValue(place.place_name);
        }

        if (typeof setPlaceId === 'function') {
            setPlaceId(place.place_id);
        }

        if (typeof setCity === 'function') {
            setCity(place.city.long_name);
        }

        if (typeof setCountry === 'function') {
            setCountry(place.country.long_name);
        }

        if (onChangeCallback) {
            onChangeCallback(place.place_name);
        }
    }

    return (
        <>
            <StyledInput readOnly={false}
                         value={searchValue !== undefined ? searchValue : props.defaultValue}
                         onChange={(e) => handleSearch(e)}
                         disabled={disabled}
                         {...props}
            />
            {(isPlacePredictionsLoading || loading) && (
                <StyledAutocompleteList>
                    <IonSkeletonText animated style={{
                        width: '100%',
                        height: '12px',
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        borderRadius: 0
                    }}/>
                </StyledAutocompleteList>
            )}
            {!isPlacePredictionsLoading && !loading && loadedPlaces.length > 0 && (
                <StyledAutocompleteList>
                    {loadedPlaces.map((place) => {
                        return <StyledAutocompleteListItem key={place.place_id} onClick={() => handlePlaceSelect(place)}>{place.place_name}</StyledAutocompleteListItem>
                    })}
                </StyledAutocompleteList>
            )}
        </>
    );
};

export default CityAutocomplete;