import React, {useState} from 'react';
import {IonAlert, IonRow} from '@ionic/react';
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

import {RideRoute} from "@models/travelAllowance/rideRoute";

import {IonCardShadowStyle, StyledButton} from "@app/travelAllowance/travelAllowance.style";

import {ReactComponent as RideIcon} from "@assets/images/travelAllowance/ride.svg";
import {ReactComponent as EditIcon} from "@assets/images/travelAllowance/edit.svg";
import {ReactComponent as BinIcon} from "@assets/images/travelAllowance/bin.svg";

import {Links} from "@app/links";

type RideRouteListRowProps = {
    rideRoute: RideRoute,
    handleRemove: (rideRouteId: number) => void;
}

const RideRouteListRow: React.FC<RideRouteListRowProps> = ({rideRoute, handleRemove}: RideRouteListRowProps) => {
    const history = useHistory();
    const {t} = useTranslation();

    const [showRemoveConfirmationAlert, setShowConfirmationAlert] = useState<boolean>(false);

    return (
        <IonCardShadowStyle>
            <IonAlert
                isOpen={showRemoveConfirmationAlert}
                onDidDismiss={() => setShowConfirmationAlert(false)}
                header={t('travelAllowance.removeConfirmationAlert.title')}
                buttons={[
                    {
                        text: t('common.alertCancel'),
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            setShowConfirmationAlert(false);
                        }
                    },
                    {
                        text: t('common.alertConfirm'),
                        handler: () => {
                            handleRemove(rideRoute.id);
                        }
                    }
                ]}
            />
            <div className="wrapper">
                <div>
                    <IonRow className="title-small">
                        {rideRoute.startingPlace}
                    </IonRow>
                    <IonRow className="title-card">
                        {rideRoute.destinationPlace}
                    </IonRow>
                    <IonRow className="subtitle">
                        {rideRoute.placeOfService.name}
                    </IonRow>
                    <IonRow className="subtitle">
                        <RideIcon/>
                        <span className="mt-4">{rideRoute.distance}&nbsp;km</span>
                    </IonRow>
                </div>
                <div className="wrapper-btn"
                     style={{flexDirection: "row", alignItems: "center"}}>
                    <StyledButton onClick={() => history.push(Links.main + Links.travelAllowance.rideRoute.edit + '/' + rideRoute.id)}>
                        <div className="btn center">
                            <EditIcon/>
                        </div>
                    </StyledButton>
                    <StyledButton onClick={() => setShowConfirmationAlert(true)}>
                        <div className="btn center">
                            <BinIcon/>
                        </div>
                    </StyledButton>
                </div>
            </div>
        </IonCardShadowStyle>
    );
};

export default RideRouteListRow;