import {Profile} from "../../../../models/profile";
import {
    Defect, DefectGroup,
    Order,
    Report,
    ReportPageContext,
    ReportRow,
    ReportRowComponent,
    ReportRowFile
} from "../../reportPage.component";
import React, {useContext, useEffect, useRef, useState} from "react";
import {IonAlert, IonCol, IonGrid, IonRow, isPlatform, useIonViewWillLeave} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {DataSelectable, RemoveDefectGroupState, RemoveDefectState} from "./reportRowForm.component";
import {
    canScanNextComponent,
    isSettingEnabled,
    orderDefects,
    validateReportRowComponentQuantity,
    validateReportRowQuantity
} from "../../../../services/report.service";
import {StyledInput} from "../../../../components/form/input/input.style";
import ArrowDownImage from "../../../../assets/images/down-arrow.svg";
import {
    StyledButton,
    StyledButtonBadge,
    StyledCircleButton,
    StyledSocialButton
} from "../../../../components/button/button.style";
import TrashRedImage from "../../../../assets/images/trash-can-red.svg";
import FlameImage from "../../../../assets/images/flame.svg";
import PhotosImage from "../../../../assets/images/photos.svg";
import QrCodeBlue from "../../../../assets/images/barcode-qr-blue.svg";
import FilesModal from "../../modals/filesModal.component";
import ListModal from "../../modals/listModal.component";
import TrashImage from "../../../../assets/images/trash-can.svg";
import {StyledTextarea} from "../../../../components/form/input/textarea.style";
import LabelScannerModal, {ReportRowComponentLabel} from "../../modals/labelScannerModal.component";
import SimpleScannerModal from "../../modals/simpleScannerModal.component";
import {ReportSetting} from "../../../../enums/report";
import {StyledComponentFormContainer} from "./reportRowForm.style";
import QrScannerModal from "../../modals/qrScannerModal.component";
import {BarcodeScanner} from "@capacitor-community/barcode-scanner";
import QrImage from "../../../../assets/images/barcode-qr-1.svg";
import ManualLabelScannerModal from "../../modals/manualLabelScannerModal.component";

export type ReportRowComponentProps = {
    orderId?: string,
    reportId?: string,
    profile?: Profile,
    specificationId?: string,
    row: ReportRow;
    component: ReportRowComponent;
    report: Report;
    order?: Order;
    reportPreview: boolean;
    modalEdit?: boolean;
    maxComponents?: number;
    onChange: (component: ReportRowComponent) => void,
    onRemove: (component: ReportRowComponent) => void,
    onSubmit: () => void,
    onAdd: () => void,
}

export interface RemoveRowComponentState {
    showAlert: boolean;
    component?: ReportRowComponent;
}

export interface ComponentMax {
    showAlert: boolean;
    component?: ReportRowComponent;
}

const ReportRowComponentForm: React.FC<ReportRowComponentProps> = (props: ReportRowComponentProps) => {

    const { t } = useTranslation();

    const [componentMax, updateComponentMax] = useState({showAlert: false, component: undefined} as ComponentMax);
    const [removeRowComponentState, updateRemoveRowComponentState] = useState({ showAlert: false, component: undefined } as RemoveRowComponentState);
    const [removeDefectState, updateRemoveDefectState] = useState({ showAlert: false, defect: undefined, defectIndex: undefined, defectGroupIndex: undefined } as RemoveDefectState);
    const [removeDefectGroupState, updateRemoveDefectGroupState] = useState({ showAlert: false, defectGroup: undefined, defectIndex: undefined } as RemoveDefectGroupState);
    const [component, setComponent] = useState<ReportRowComponent>(props.component);
    const [showFiles, updateShowFiles] = useState<boolean>(false);
    const [showDefectList, updateShowDefectList] = useState<boolean>(false);
    const [showLabelScanner, updateShowLabelScanner] = useState<boolean>(false);
    const [showManualLabelScanner, updateShowManualLabelScanner] = useState<boolean>(false);
    const [showQrLabelScanner, updateShowQrLabelScanner] = useState<boolean>(false);
    const [showSimpleScanner, updateShowSimpleScanner] = useState<boolean>(false);
    const [selectedDefect, setSelectedDefect] = useState<Defect>();
    const [selectedDefectIndex, setSelectedDefectIndex] = useState<number>();
    const [selectedDefectGroupIndex, setSelectedDefectGroupIndex] = useState<number>();
    const [defects, updateDefects] = useState<DataSelectable[]>();
    const [moreDefects, updateMoreDefects] = useState<DataSelectable[]>();
    const [scannedLabels, updateScannedLabels] = useState<DataSelectable[]>([]);
    const [singleScanField, setSingleScanField] = useState<string>('');
    const [showSingleScanModal, setShowSingleScanModal] = useState<boolean>(false);
    const [showPermissionAlert, setShowPermissionAlert] = useState<boolean>(false);

    const prevComponentRef = useRef<ReportRowComponent|undefined>();

    useEffect(() => {
        if (prevComponentRef.current) {
            const timeOutId = setTimeout(() => props.onChange(component), 200);
            prevComponentRef.current = component;
            return () => clearTimeout(timeOutId);
        }
        prevComponentRef.current = component;
    }, [component])

    const addDefect = (defectGroupIndex: number) => {
        const tmp= component.defectGroups;
        const d = tmp[defectGroupIndex];

        if (defects && defects.length == 1) {
            if (d.defects) {
                d.defects.push({
                    name: defects[0].name,
                    id: defects[0].id,
                    defect: defects[0],
                });
            }
        } else {
            if (d.defects) {
                d.defects.push({
                    name: '',
                });
            }
        }

        updateDefectGroup(d, defectGroupIndex);
    }

    const addDefectGroup = () => {
        const tmp = component.defectGroups;

        if (defects && defects.length == 1) {
            tmp.push({
                quantity: props.order?.enable_single_components && props.order?.sub_type === 'CONTROL' ? 1 : undefined,
                defects: [
                    {
                        name: defects[0].name,
                        id: defects[0].id,
                        defect: defects[0],
                    }
                ]
            });
        } else {
            tmp.push({
                quantity: props.order?.enable_single_components && props.order?.sub_type === 'CONTROL' ? 1 : undefined,
                defects: [
                    {
                        name: ''
                    }
                ]
            });
        }

        setComponent({
            ...component,
            defectGroups: tmp
        })
    }

    const updateSelectedDefect = (defect: DataSelectable) => {
        const tmp = component.defectGroups;

        const group = selectedDefectGroupIndex !== undefined ? tmp[selectedDefectGroupIndex] : null;
        if (group && group.defects) {
            const d = selectedDefectIndex !== undefined ? group.defects[selectedDefectIndex] : null;

            if (d && selectedDefectIndex !== undefined && selectedDefectGroupIndex !== undefined) {
                d.id = defect.id;
                d.name = defect.name;
                d.defect = defect;
                d.other = defect.manual ? defect.name : undefined;

                group.defects.splice(selectedDefectIndex, 1, d)
                updateDefectGroup(group, selectedDefectGroupIndex);
            }

            updateShowDefectList(false);
        }

    }

    const updateFiles = (files: ReportRowFile[]) => {
        setComponent({
            ...component,
            files: files
        })

        updateShowFiles(false)
    }

    const updateLabelScan = (label: ReportRowComponentLabel) => {
        let tmp = component;
        if (label.quantity) {
            tmp.quantity = label.quantity;
        }

        if (label.componentNumberText) {
            tmp.componentNumberText = label.componentNumberText;
        }

        if (label.serialNumber) {
            tmp.serialNumber = label.serialNumber;
        }

        if (label.levNumber) {
            tmp.levNumber = label.levNumber;
        }

        if (label.deliveryNote) {
            tmp.deliveryNote = label.deliveryNote
        }

        if (label.comment) {
            tmp.comment = label.comment;
        }

        if (label.batchNumber) {
            tmp.batchNumber = label.batchNumber;
        }

        if (label.materialNumber) {
            tmp.materialNumber = label.materialNumber;
        }

        setComponent({
            ...component,
            quantity: tmp.quantity,
            componentNumberText: tmp.componentNumberText,
            serialNumber: tmp.serialNumber,
            levNumber: tmp.levNumber,
            deliveryNote: tmp.deliveryNote,
            comment: tmp.comment,
            batchNumber: tmp.batchNumber,
            materialNumber: tmp.materialNumber,
        })

        updateScannedLabels([]);
    }

    const updateSimpleScan = (value: string) => {
        setComponent({
            ...component,
            componentNumberText: value
        })
    }

    useIonViewWillLeave(() => {
        updateScannedLabels([]);
    });

    const updateComponentOnScannerClose = () => {

        let label:ReportRowComponentLabel = {
            comment: null,
            quantity: null,
            deliveryNote: null,
            levNumber: null,
            serialNumber: null,
            componentNumberText: null,
            batchNumber: null,
            materialNumber: null,
        };

        scannedLabels.forEach((data) => {
            switch (data.id) {
                case 'quantity':
                    label.quantity = data.name;
                    break;
                case 'deliveryNote':
                    label.deliveryNote = data.name;
                    break;
                case 'levNumber':
                    label.levNumber = data.name;
                    break;
                case 'serialNumber':
                    label.serialNumber = data.name;
                    break;
                case 'componentNumberText':
                    label.componentNumberText = data.name;
                    break;
                case 'batchNumber':
                    label.batchNumber = data.name;
                    break;
                case 'materialNumber':
                    label.materialNumber = data.name;
                    break;
            }
        })

        updateLabelScan(label);
    }

    const updateComponentField = (field: string, value: string|number) => {
        let isNum = value.toString().match(/^[0-9]+$/) != null;
        let valueNumeric = undefined;
        if (isNum) {
            valueNumeric = parseInt(value.toString());
        } else {
            if (value === '') {
                valueNumeric = undefined;
            } else {
                valueNumeric = 0;
            }
        }

        switch (field) {
            case 'quantity':
                setComponent({
                    ...component,
                    quantity: valueNumeric
                })
                const c = {...props.component};
                if (props.maxComponents && valueNumeric && valueNumeric > props.maxComponents) {
                    updateComponentMax({showAlert: true, component: c})
                }
                break;
            case 'componentNumberText':
                setComponent({
                    ...component,
                    componentNumberText: value.toString()
                })
                break;
            case 'serialNumber':
                setComponent({
                    ...component,
                    serialNumber: value.toString()
                })
                break;
            case 'levNumber':
                setComponent({
                    ...component,
                    levNumber: value.toString()
                })
                break;
            case 'deliveryNote':
                setComponent({
                    ...component,
                    deliveryNote: value.toString()
                })
                break;
            case 'comment':
                setComponent({
                    ...component,
                    comment: value.toString()
                })
                break;
            case 'batchNumber':
                setComponent({
                    ...component,
                    batchNumber: value.toString()
                })
                break;
            case 'materialNumber':
                setComponent({
                    ...component,
                    materialNumber: value.toString()
                })
                break;
        }
    }

    const updateDefectField = (defect: DefectGroup, field: string, value: string|number, key: number) => {
        let isNum = value.toString().match(/^[0-9]+$/) != null;
        let valueNumeric = undefined;
        if (isNum) {
            valueNumeric = parseInt(value.toString());
        } else {
            if (value === '') {
                valueNumeric = undefined;
            } else {
                valueNumeric = 0;
            }
        }

        switch (field) {
            case 'quantity':
                updateDefectGroup({...defect, quantity: valueNumeric}, key);
                break;
            case 'repaired':
                updateDefectGroup({...defect, repaired: valueNumeric}, key);
                break;
            case 'notRepaired':
                updateDefectGroup({...defect, notRepaired: valueNumeric}, key);
                break;
            case 'repairedSingle':
                updateDefectGroup({...defect, repaired: valueNumeric, notRepaired: 0}, key);
                break;
            case 'notRepairedSingle':
                updateDefectGroup({...defect, notRepaired: valueNumeric, repaired: 0}, key);
                break;
        }
    }

    const updateDefectGroup = (defect: DefectGroup, index: number) => {
        const tmp = component.defectGroups;
        tmp.splice(index, 1, defect);
        updateShowDefectList(false);
        setComponent({
            ...component,
            defectGroups: tmp
        })
    }

    const selectDefect = async (defectGroupIndex: number, defect: Defect, index: number) => {
        setSelectedDefect(defect);
        setSelectedDefectIndex(index);
        setSelectedDefectGroupIndex(defectGroupIndex);
        await fetchDefects();
        updateShowDefectList(true);
    };

    useEffect(()=> {
        fetchDefects();
    }, [])

    const removeDefectGroup = (defectGroup: DefectGroup, index: number) => {
        const tmp = component.defectGroups;
        tmp.splice(index, 1);

        setComponent({
            ...component,
            defectGroups: tmp
        })
    }

    const removeDefect = (defectGroupIndex: number, defect: Defect, index: number) => {
        const tmp = component.defectGroups;
        const d = tmp[defectGroupIndex];

        if (d.defects) {
            d.defects.splice(index, 1);
        }

        updateDefectGroup(d, defectGroupIndex);
    }

    const fetchDefects = async () => {
        if (defects === undefined && props.specificationId && props.specificationId != '0') {
            updateDefects([]);
            updateMoreDefects([]);
            await orderDefects(props.specificationId)
                .then(response => {
                    updateDefects(response.data.specification_defects);
                    updateMoreDefects(response.data.general_defects);
                });
        }
    }

    const getNumberOfComponent = () => {
        const tmp = props.row.components?.slice().reverse();
        return tmp ? tmp.indexOf(props.component) + 1 : null;
    }

    useIonViewWillLeave(() => {
        stopScan();
    });

    const handleSingleScan = async (value: string) => {
        if (singleScanField) {
            if (singleScanField === 'quantity') {
                value = value.replace(/[^0-9]/g, '');
            }
            updateComponentField(singleScanField, value);
            stopScan();
            setShowSingleScanModal(false);
            setSingleScanField('');
        }
    };

    const startSingleScan = async (field: string) => {
        setSingleScanField(field);
        setShowSingleScanModal(true);
    };

    const checkPermission = async () => {
        // check or request permission
        const status = await BarcodeScanner.checkPermission({ force: true });

        if (status.granted) {
            // the user granted permission
            return true;
        }

        return false;
    };

    const stopScan = () => {
        BarcodeScanner.showBackground();
        BarcodeScanner.stopScan();
    };

    const scanSingleField = async (field: string) => {
        let hasPermission = await checkPermission();
        if (!hasPermission) {
            setShowPermissionAlert(true);
        }
        startSingleScan(field);
    }

    const reportPageContext = useContext(ReportPageContext);

    return <StyledComponentFormContainer className="row-component">
        <IonRow>
            <IonCol size="1"><StyledCircleButton disabled={true} className="white-border m-10 small">{getNumberOfComponent()}</StyledCircleButton></IonCol>
            <IonCol size="1" offset="10">
                {!props.modalEdit && !props.reportPreview && props.row.components && props.row.components?.length > 1 && <StyledCircleButton className="light-red m-10" onClick={() => updateRemoveRowComponentState({showAlert: true, component: component})}><img src={TrashImage} /></StyledCircleButton>}
            </IonCol>
        </IonRow>

        {(isPlatform('ios') || isPlatform('android')) &&
            <>
                <IonRow>
                    {!props.reportPreview && props.order?.enable_nested_reporting && props.order.scanning_settings.prefix && <IonCol size="12">
                        <StyledSocialButton className="report-button black-text" onClick={() => updateShowLabelScanner(true)}>
                            <img className="button-image" src={QrCodeBlue} />
                            {t('reportPage.reportForm.nestedReporting.scanLabel')}
                        </StyledSocialButton>
                    </IonCol>}

                    {!props.reportPreview && props.order?.enable_nested_reporting && props.order.scanning_settings.manual && <IonCol size="12">
                        <StyledSocialButton className="report-button black-text" onClick={() => updateShowManualLabelScanner(true)}>
                            <img className="button-image" src={QrCodeBlue} />
                            {t('reportPage.reportForm.nestedReporting.scanManualLabel')}
                        </StyledSocialButton>
                    </IonCol>}

                    {!props.reportPreview && props.order?.enable_nested_reporting && props.order.scanning_settings.qr && <IonCol size="12">
                        <StyledSocialButton className="report-button black-text" onClick={() => updateShowQrLabelScanner(true)}>
                            <img className="button-image" src={QrCodeBlue} />
                            {t('reportPage.reportForm.nestedReporting.scanQrLabel')}
                        </StyledSocialButton>
                    </IonCol>}
                </IonRow>

                <IonRow>
                    <IonCol size="12">
                        {!props.reportPreview && props.order?.enable_single_components && <StyledSocialButton className="report-button black-text" onClick={() => updateShowSimpleScanner(true)}>
                            <img className="button-image" src={QrCodeBlue} />
                            {t('reportPage.reportForm.nestedReporting.scanSimpleLabel')}
                        </StyledSocialButton> }
                    </IonCol>
                </IonRow>
            </>
        }

        <IonRow>
            <IonCol size="6" className="label required">
                {t('reportPage.reportForm.nestedReporting.componentNumberText')}
            </IonCol>
            <IonCol size="6" className="label required">
                {t('reportPage.reportForm.nestedReporting.componentQuantity')}
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol size={!props.reportPreview && props.order && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                <StyledInput disabled={props.reportPreview} type="text"
                             onKeyPress={event => {
                                 if (event.key == 'Enter' && !props.reportPreview) {
                                     const input = event.target as HTMLInputElement;
                                     input.blur();
                                 }
                             }}
                             onChange={(e) => updateComponentField('componentNumberText',e.target.value?? undefined)}
                             className={((reportPageContext.getFormSubmitted() || component.new) && !component.componentNumberText) || (!props.reportPreview && component.componentNumberText && props.order?.nested_reporting_components.length && !props.order.nested_reporting_components.includes(component.componentNumberText)) ? 'hasErrors' : ''}
                             value={component.componentNumberText} placeholder={t('reportPage.reportForm.enter')} />

                { (component.componentNumberText && props.order?.nested_reporting_components && props.order?.nested_reporting_components.length > 0 && !props.order?.nested_reporting_components.includes(component.componentNumberText)) &&
                    <p className="fieldErrorMessage">{t('reportPage.reportForm.componentNumberNotAllowed')}</p>
                }
            </IonCol>
            {!props.reportPreview && props.order && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('componentNumberText')}>
                <img src={QrImage} />
            </div></IonCol>}

            <IonCol size={!props.reportPreview && props.order && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                <StyledInput disabled={props.reportPreview || props.order?.enable_single_components} type="text" pattern="[0-9]*" inputMode="tel" min={0}
                             onKeyPress={event => {
                                 if (event.key == 'Enter' && !props.reportPreview) {
                                     const input = event.target as HTMLInputElement;
                                     input.blur();
                                 }
                             }}
                             onChange={(e) => updateComponentField('quantity', e.target.value?? undefined)}
                             className={reportPageContext.getFormSubmitted() && props.order && ((!component.quantity && component.quantity !== 0) || !validateReportRowComponentQuantity(props.order, component))  ? 'hasErrors' : ''}
                             value={component.quantity} placeholder={t('reportPage.reportForm.enter')} />
            </IonCol>

            {!props.reportPreview && props.order && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('quantity')}>
                <img src={QrImage} />
            </div></IonCol>}
        </IonRow>

        <IonRow>
            {props.order && isSettingEnabled(ReportSetting.LEV_NUMBER, props.order) && <IonCol size="6" className={props.order && isSettingEnabled(ReportSetting.LEV_NUMBER_REQUIRED, props.order) ? "label required" : "label"}>
                {t('reportPage.reportForm.nestedReporting.levNumber')}
            </IonCol>}
            {props.order && isSettingEnabled(ReportSetting.SERIAL_NUMBER, props.order) && <IonCol size="6" className={props.order && isSettingEnabled(ReportSetting.SERIAL_NUMBER_REQUIRED, props.order) ? "label required" : "label"}>
                {t('reportPage.reportForm.nestedReporting.serialNumber')}
            </IonCol>}
        </IonRow>
        <IonRow>
            {props.order && isSettingEnabled(ReportSetting.LEV_NUMBER, props.order) &&
                <>
                    <IonCol size={!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                        <StyledInput disabled={props.reportPreview} type="text"
                                     onKeyPress={event => {
                                         if (event.key == 'Enter' && !props.reportPreview) {
                                             const input = event.target as HTMLInputElement;
                                             input.blur();
                                         }
                                     }}
                                     className={reportPageContext.getFormSubmitted() && props.order && isSettingEnabled(ReportSetting.LEV_NUMBER_REQUIRED, props.order) && !component.levNumber ? 'hasErrors' : ''}
                                     onChange={(e) => updateComponentField('levNumber',e.target.value?? undefined)}
                                     value={component.levNumber} placeholder={t('reportPage.reportForm.enter')} />
                    </IonCol>

                    {!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('levNumber')}>
                        <img src={QrImage} />
                    </div></IonCol>}
                </>
            }
            {props.order && isSettingEnabled(ReportSetting.SERIAL_NUMBER, props.order) &&
                <>
                    <IonCol size={!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                        <StyledInput disabled={props.reportPreview} type="text"
                                     onKeyPress={event => {
                                         if (event.key == 'Enter' && !props.reportPreview) {
                                             const input = event.target as HTMLInputElement;
                                             input.blur();
                                         }
                                     }}
                                     className={reportPageContext.getFormSubmitted() && props.order && isSettingEnabled(ReportSetting.SERIAL_NUMBER_REQUIRED, props.order) && !component.serialNumber ? 'hasErrors' : ''}
                                     onChange={(e) => updateComponentField('serialNumber',e.target.value?? undefined)}
                                     value={component.serialNumber} placeholder={t('reportPage.reportForm.enter')} />
                    </IonCol>

                    {!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('serialNumber')}>
                        <img src={QrImage} />
                    </div></IonCol>}
                </>
            }
        </IonRow>

        <IonRow>
            {props.order && isSettingEnabled(ReportSetting.DELIVERY_NOTE, props.order) && <IonCol size="6" className={props.order && isSettingEnabled(ReportSetting.DELIVERY_NOTE_REQUIRED, props.order) ? "label required" : "label"}>
                {t('reportPage.reportForm.nestedReporting.deliveryNote')}
            </IonCol>}
            {props.order && isSettingEnabled(ReportSetting.BATCH_NUMBER, props.order) && <IonCol size="6" className={props.order && isSettingEnabled(ReportSetting.BATCH_NUMBER_REQUIRED, props.order) ? "label required" : "label"}>
                {t('reportPage.reportForm.nestedReporting.batchNumber')}
            </IonCol>}
        </IonRow>
        <IonRow>
            {props.order && isSettingEnabled(ReportSetting.DELIVERY_NOTE, props.order) &&
                <>
                    <IonCol size={!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                    <StyledInput disabled={props.reportPreview} type="text"
                                 onKeyPress={event => {
                                     if (event.key == 'Enter' && !props.reportPreview) {
                                         const input = event.target as HTMLInputElement;
                                         input.blur();
                                     }
                                 }}
                                 className={reportPageContext.getFormSubmitted() && props.order && isSettingEnabled(ReportSetting.DELIVERY_NOTE_REQUIRED, props.order) && !component.deliveryNote ? 'hasErrors' : ''}
                                 onChange={(e) => updateComponentField('deliveryNote',e.target.value?? undefined)}
                                 value={component.deliveryNote}
                                 placeholder={t('reportPage.reportForm.enter')} />
                    </IonCol>

                    {!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('deliveryNote')}>
                        <img src={QrImage} />
                    </div></IonCol>}
                </>
            }
            {props.order && isSettingEnabled(ReportSetting.BATCH_NUMBER, props.order) &&
                <>
                    <IonCol size={!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                        <StyledInput disabled={props.reportPreview} type="text"
                                     onKeyPress={event => {
                                         if (event.key == 'Enter' && !props.reportPreview) {
                                             const input = event.target as HTMLInputElement;
                                             input.blur();
                                         }
                                     }}
                                     onChange={(e) => updateComponentField('batchNumber',e.target.value?? undefined)}
                                     value={component.batchNumber}
                                     className={reportPageContext.getFormSubmitted() && props.order && isSettingEnabled(ReportSetting.BATCH_NUMBER_REQUIRED, props.order) && !component.batchNumber ? 'hasErrors' : ''}
                                     placeholder={t('reportPage.reportForm.enter')} />
                    </IonCol>

                    {!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('batchNumber')}>
                        <img src={QrImage} />
                    </div></IonCol>}
                </>}
        </IonRow>

        {props.order && isSettingEnabled(ReportSetting.MATERIAL_NUMBER, props.order) &&
            <>
                <IonRow>
                    <IonCol size="6" className={props.order && isSettingEnabled(ReportSetting.MATERIAL_NUMBER_REQUIRED, props.order) ? "label required" : "label"}>
                        {t('reportPage.reportForm.nestedReporting.materialNumber')}
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size={!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) ? "5" : "6"}>
                        <StyledInput disabled={props.reportPreview} type="text"
                                     onKeyPress={event => {
                                         if (event.key == 'Enter' && !props.reportPreview) {
                                             const input = event.target as HTMLInputElement;
                                             input.blur();
                                         }
                                     }}
                                     onChange={(e) => updateComponentField('materialNumber',e.target.value?? undefined)}
                                     value={component.materialNumber}
                                     className={reportPageContext.getFormSubmitted() && props.order && isSettingEnabled(ReportSetting.MATERIAL_NUMBER_REQUIRED, props.order) && !component.materialNumber ? 'hasErrors' : ''}
                                     placeholder={t('reportPage.reportForm.enter')} />
                    </IonCol>

                    {!props.reportPreview && props.order.is_enable_single_label_element_scan && (isPlatform('ios') || isPlatform('android')) && <IonCol size="1" className="scanSingleField"><div onClick={() => scanSingleField('materialNumber')}>
                        <img src={QrImage} />
                    </div></IonCol>}
                </IonRow>
            </>
        }

        <IonRow>
            <IonCol size="12" className="label">
                {t('reportPage.reportForm.nestedReporting.comment')}
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol size="12">
                <StyledTextarea disabled={props.reportPreview}
                                onKeyPress={event => {
                                    if (event.key == 'Enter' && !props.reportPreview) {
                                        const input = event.target as HTMLInputElement;
                                        input.blur();
                                    }
                                }}
                                className="full-width"
                                onChange={(e) => updateComponentField('comment',e.target.value?? undefined)}
                                value={component.comment}
                                placeholder={t('reportPage.reportForm.enter')} />
            </IonCol>
        </IonRow>

        {
            component.defectGroups.map((defectGroup, key) =>
                <div key={'component_defects_group_'+key}>
                    { props.order?.sub_type === 'CONTROL' &&
                        <>
                        {!props.order?.enable_single_components && <IonRow>
                                <IonCol size="12" className="label required">
                                    {t('reportPage.reportForm.defects.quantity')}
                                </IonCol>

                                <IonCol size="11">
                                    <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*" inputMode="tel" min={0} value={defectGroup.quantity}
                                                 onKeyPress={event => {
                                                     if (event.key == 'Enter' && !props.reportPreview) {
                                                         const input = event.target as HTMLInputElement;
                                                         input.blur();
                                                     }
                                                 }}
                                                 className={reportPageContext.getFormSubmitted() && !defectGroup.quantity && defectGroup.quantity !== 0 ? 'hasErrors' : ''}
                                                 onChange={(e) => updateDefectField(defectGroup, 'quantity', e.target.value ?? undefined, key)}
                                                 placeholder={t('reportPage.reportForm.enter')} />
                                </IonCol>

                                <IonCol size="1" className="center">
                                    {!props.reportPreview && <StyledCircleButton className="white" onClick={() => updateRemoveDefectGroupState({showAlert: true, defectGroup: defectGroup, defectGroupIndex: key})}><img src={TrashRedImage} /></StyledCircleButton>}
                                </IonCol>
                            </IonRow>}

                            {defectGroup.defects?.map((defect: Defect, defectKey) =>
                                <IonRow key={'defects_' + key + '_' + defectKey}>
                                    <IonCol size={props.order?.enable_single_components ? "5" : "6"} className="select" onClick={() => {
                                        if(!props.reportPreview) {
                                            selectDefect(key, defect, defectKey)
                                        }
                                    }}>
                                        <StyledInput readOnly={true} className={reportPageContext.getFormSubmitted() && (!defect.name) ? 'hasErrors' : ''} value={defect.name || defect.other} placeholder={t("common.select")}/>
                                        <img className="down" src={ArrowDownImage} />
                                    </IonCol>

                                    {defectGroup.defects.length > 1 && <IonCol size="1" className="center">
                                        {!props.reportPreview && <StyledCircleButton className="white" onClick={() => updateRemoveDefectState({showAlert: true, defect: defect, defectIndex: defectKey, defectGroupIndex: key})}><img src={TrashRedImage} /></StyledCircleButton>}
                                    </IonCol>}

                                    {props.specificationId != '0' && !props.reportPreview && defectKey === 0 &&
                                        <IonCol size="5">
                                            <StyledSocialButton className="report-button black-text" onClick={() => addDefect(key)}>
                                                <img className="button-image" src={FlameImage}/>
                                                {t('reportPage.reportForm.addAdditionalDefect')}
                                            </StyledSocialButton>
                                        </IonCol>}

                                    {props.specificationId != '0' && !props.reportPreview && defectKey === 0 && props.order?.enable_single_components &&
                                        <IonCol size="1" className="center">
                                            {!props.reportPreview && <StyledCircleButton className="white" onClick={() => updateRemoveDefectGroupState({showAlert: true, defectGroup: defectGroup, defectGroupIndex: key})}><img src={TrashRedImage} /></StyledCircleButton>}
                                        </IonCol>
                                    }
                                </IonRow>
                            )}
                        </>
                    }

                    { props.order?.sub_type === 'OPTIMIZATION' &&
                        <>
                            <IonRow>
                                { !props.order.enable_single_components &&
                                    <>
                                        <IonCol size="5" className="label required">
                                            {t('reportPage.reportForm.defects.repaired')}
                                        </IonCol>
                                        <IonCol size="5" className="label required">
                                            {t('reportPage.reportForm.defects.notRepaired')}
                                        </IonCol>

                                        <IonCol size="5">
                                            <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*" inputMode="tel" min={0} value={defectGroup.repaired}
                                                         className={reportPageContext.getFormSubmitted() && !defectGroup.repaired && defectGroup.repaired !== 0 ? 'hasErrors' : ''}
                                                         onChange={(e) => updateDefectField(defectGroup, 'repaired', e.target.value ?? undefined, key)}
                                                         placeholder={t('reportPage.reportForm.enter')} />
                                        </IonCol>
                                        <IonCol size="5">
                                            <StyledInput disabled={props.reportPreview} type="text" pattern="[0-9]*" inputMode="tel" min={0} value={defectGroup.notRepaired}
                                                         className={reportPageContext.getFormSubmitted() && !defectGroup.notRepaired && defectGroup.notRepaired !== 0 ? 'hasErrors' : ''}
                                                         onChange={(e) => updateDefectField(defectGroup, 'notRepaired', e.target.value ?? undefined, key)}
                                                         placeholder={t('reportPage.reportForm.enter')} />
                                        </IonCol>
                                    </>
                                }

                                { props.order.enable_single_components &&
                                    <>
                                        <IonCol size="5">
                                            <StyledSocialButton disabled={props.reportPreview}
                                                                className={reportPageContext.getFormSubmitted() && (defectGroup.repaired === undefined || defectGroup.notRepaired === undefined ) ? 'hasErrors report-button black-text' : defectGroup.repaired === 1 ? 'report-button black-text selected' : 'report-button black-text'}
                                                                onClick={() => {
                                                                    updateDefectField(defectGroup, 'repairedSingle', '1', key)
                                                                }}>
                                                {t('reportPage.reportForm.defects.ok')}
                                            </StyledSocialButton>
                                        </IonCol>

                                        <IonCol size="5">
                                            <StyledSocialButton disabled={props.reportPreview}
                                                                className={reportPageContext.getFormSubmitted() && (defectGroup.repaired === undefined || defectGroup.notRepaired === undefined ) ? 'hasErrors report-button black-text' : defectGroup.notRepaired === 1 ? 'report-button black-text selected' : 'report-button black-text'}
                                                                onClick={() => {
                                                                    updateDefectField(defectGroup, 'notRepairedSingle', '1', key)
                                                                }}>
                                                {t('reportPage.reportForm.defects.nok')}
                                            </StyledSocialButton>
                                        </IonCol>
                                    </>
                                }

                                <IonCol size="2" className="center">
                                    {!props.reportPreview && <StyledCircleButton className="white" onClick={() => updateRemoveDefectGroupState({showAlert: true, defectGroup: defectGroup, defectGroupIndex: key})}><img src={TrashRedImage} /></StyledCircleButton>}
                                </IonCol>
                            </IonRow>

                            {defectGroup.defects?.map((defect: Defect, defectKey) =>
                                <IonRow key={'defects_' + key + '_' + defectKey}>
                                    <IonCol size="5" className="select"  onClick={() => {
                                        if(!props.reportPreview) {
                                            selectDefect(key, defect, defectKey)
                                        }
                                    }}>
                                        <StyledInput readOnly={true} className={reportPageContext.getFormSubmitted() && (!defect.name) ? 'hasErrors' : ''} value={defect.name || defect.other} placeholder={t("common.select")}/>
                                        <img className="down" src={ArrowDownImage} />
                                    </IonCol>

                                    {defectGroup.defects.length > 1 && <IonCol size="1" className="center">
                                        {!props.reportPreview && <StyledCircleButton className="white" onClick={() => updateRemoveDefectState({showAlert: true, defect: defect, defectIndex: defectKey, defectGroupIndex: key})}><img src={TrashRedImage} /></StyledCircleButton>}
                                    </IonCol>}

                                    {props.specificationId != '0' && !props.reportPreview && defectKey === 0 &&
                                        <IonCol size="5">
                                            <StyledSocialButton className="report-button black-text" onClick={() => addDefect(key)}>
                                                <img className="button-image" src={FlameImage}/>
                                                {t('reportPage.reportForm.addAdditionalDefect')}
                                            </StyledSocialButton>
                                        </IonCol>}
                                </IonRow>
                            )}
                        </>
                    }
                </div>
            )
        }
        {props.specificationId != '0' && !props.reportPreview && (!props.order?.enable_single_components || (props.order.enable_single_components && component.defectGroups.length === 0)) && <IonRow>
                <IonCol>
                    <StyledSocialButton className="report-button black-text" onClick={() => addDefectGroup()}>
                        <img className="button-image" src={FlameImage}/>
                        {t('reportPage.reportForm.addDefect')}
                    </StyledSocialButton>
                </IonCol>
            </IonRow>
        }

        {(!props.reportPreview || (props.reportPreview && component.files.length > 0)) && <IonRow>
            <IonCol>
                <StyledSocialButton className="report-button black-text" onClick={() => updateShowFiles(true)}>
                    <img className="button-image" src={PhotosImage} />
                    {!props.reportPreview ? t('reportPage.reportForm.addFiles') : t('reportPage.reportForm.previewFiles')}
                    {component.files.length > 0 && <StyledButtonBadge className="badge default-position">{component.files.length}</StyledButtonBadge>}
                </StyledSocialButton>
            </IonCol>
        </IonRow>}

        <IonAlert
            isOpen={removeRowComponentState.showAlert}
            onDidDismiss={() => updateRemoveRowComponentState({showAlert: false, component: undefined})}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateRemoveRowComponentState({showAlert: false, component: undefined})
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        if (removeRowComponentState.component !== undefined) {
                            props.onRemove(removeRowComponentState.component);
                        }
                    }
                }
            ]}
        />

        <IonAlert
            isOpen={removeDefectState.showAlert}
            onDidDismiss={() => updateRemoveDefectState({showAlert: false, defect: undefined, defectIndex: undefined})}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateRemoveDefectState({showAlert: false, defect: undefined, defectIndex: undefined})
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        if (removeDefectState.defect !== undefined && removeDefectState.defectIndex !== undefined && removeDefectState.defectGroupIndex !== undefined) {
                            removeDefect(removeDefectState.defectGroupIndex, removeDefectState.defect, removeDefectState.defectIndex);
                        }
                    }
                }
            ]}
        />

        <IonAlert
            isOpen={removeDefectGroupState.showAlert}
            onDidDismiss={() => updateRemoveDefectGroupState({showAlert: false, defectGroup: undefined, defectGroupIndex: undefined})}
            header={t('common.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateRemoveDefectGroupState({showAlert: false, defectGroup: undefined, defectGroupIndex: undefined})
                    },
                },
                {
                    text: t('common.alertConfirm'),
                    handler: async () => {
                        if (removeDefectGroupState.defectGroup !== undefined && removeDefectGroupState.defectGroupIndex !== undefined) {
                            removeDefectGroup(removeDefectGroupState.defectGroup, removeDefectGroupState.defectGroupIndex);
                        }
                    }
                }
            ]}
        />

        <IonAlert
            isOpen={componentMax.showAlert}
            onDidDismiss={() => updateComponentMax({showAlert: false, component: undefined})}
            header={t('report.maxComponents.alertHeader')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        updateComponentMax({showAlert: false, component: undefined})
                        component.quantity = '';
                    },
                },
                {
                    text: t('common.alertConfirm'),
                }
            ]}
        />

        {showDefectList && <ListModal list={defects} moreList={moreDefects} isOpen={showDefectList} onClose={() => updateShowDefectList(false)} selected={selectedDefect?.id} allowManual={!props.profile} onSubmit={updateSelectedDefect} search={true}  />}
        <FilesModal reportPreview={props.reportPreview} files={component.files} isOpen={showFiles} onClose={() => updateShowFiles(false)} onSubmit={(files) => { updateFiles(files); }} />
        <LabelScannerModal order={props.order} isOpen={showLabelScanner} onSingleSubmit={(labelName, labelValue) => {
            let scanned:DataSelectable[] = scannedLabels;
            scanned.push({
                id: labelName,
                name: labelValue
            });
            updateScannedLabels(scanned);
        }} onSubmit={(label) => {
            updateLabelScan(label);
            updateShowLabelScanner(false);
        }} onClose={() => {
            updateShowLabelScanner(false);
            updateComponentOnScannerClose();
            updateScannedLabels([]);
        }} />

        <SimpleScannerModal order={props.order} isOpen={showSimpleScanner} onSubmit={(value) => {
            updateSimpleScan(value);
            updateShowSimpleScanner(false);
        }} onClose={() => {
            updateShowSimpleScanner(false);
        }} />

        <QrScannerModal order={props.order} isOpen={showSingleScanModal} onClose={() => {
            setShowSingleScanModal(false);
            setSingleScanField('');
        }} onSubmit={(value) => {
            handleSingleScan(value);
        }} />

        <ManualLabelScannerModal order={props.order} isOpen={showManualLabelScanner} isQr={false} onClose={() => {
            updateShowManualLabelScanner(false);
        }} onSubmit={(value) => {
            updateLabelScan(value);
            updateShowManualLabelScanner(false);
        }} />

        <ManualLabelScannerModal order={props.order} isOpen={showQrLabelScanner} isQr={true} onClose={() => {
            updateShowQrLabelScanner(false);
        }} onSubmit={(value) => {
            updateLabelScan(value);
            updateShowQrLabelScanner(false);
        }} />

        <IonAlert
            isOpen={showPermissionAlert}
            onDidDismiss={() => setShowPermissionAlert(false)}
            header={t('common.permissions.alert')}
            buttons={[
                {
                    text: t('common.alertCancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        setShowPermissionAlert(false);
                    },
                },
                {
                    text: t('common.permissions.goToAppSettings'),
                    handler: async () => {
                        setShowPermissionAlert(false);
                        BarcodeScanner.openAppSettings();
                    }
                }
            ]}
        />
    </StyledComponentFormContainer>;
}

export default ReportRowComponentForm;